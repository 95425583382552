import React from "react";
import resumeFile from "../documents/CV.pdf";

const Resume = ({ classicHeader, darkTheme }) => {
  const educationDetails = [
    {
      yearRange: "2014 - 2018",
      title: "Civil Engineering",
      place: "Adama Science And Technology University",
      desc: "Bachelor Degree.",
    },
    {
      // yearRange: "2021 - 2022",
      title: "Full-Stack Web Development ",
      place: "Evangadi Networks",
      desc: "Certificate.",
    },
  // //   {
  //     yearRange: "2009 - 2012",
  //     title: "Master Degree",
  //     place: "Harvard University",
  //     desc: "Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
  //   },
  ];

  const experienceDetails = [
    // {
      // yearRange: "Jan 2023 - June 2023 ",
    //   title: "Junior Web Developer",
    //   place: " Atlantic IT Solutions",
    //   desc: " Assisted with the creation of websites and updated existing web applications using scripting languages.",
    // },
    {
      yearRange: "Addis Ababa, Ethiopia",
      title: "Junior Web Developer",
      place: " BarokTech PLC & Atlantic IT Solutions",
      desc: " Assisted members of the web development team who program websites using HTML, CSS, and JavaScript code. Assisted with the creation of websites and updated existing web applications using scripting languages and Created user-friendly and efficient websites that are visually appealing and easy to navigate.",
    },
    {
      yearRange: "Addis Ababa, Ethiopia",
      title: "Freight Forwarder",
      place: "Trust International Freight Forwarding plc & Galaxy Freight Forwarding plc",
      desc: "Coordinating the transportation of goods, selecting optimal routes. Managing shipping documentation, and facilitating customs clearance and Making sure goods are transported in the safest, most efficient and cost-effective way.",
    },
  ];

  const skills = [
    {
      name: "Web Design",
      percent: 85,
    },
    {
      name: "HTML/CSS",
      percent: 95,
    },
    {
      name: "JavaScript",
      percent: 90,
    },
    {
      name: "React JS",
      percent: 95,
    },
    {
      name: "Node Js",
      percent: 80,
    },
    {
      name: "Bootstrap",
      percent: 99,
    },
    {
      name: "MySQL",
      percent: 90,
    },
  ];

  return (
    <section
      id="resume"
      className={"section " + (darkTheme ? "bg-dark-1" : "")}
    >
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
            }
          >
            Summary
          </h2>
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            {" "}
            Resume
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}
        <div className="row gx-5">
          {/* My Education */}
          <div className="col-md-6">
            <h2
              className={
                "text-6 fw-600 mb-4 " + (darkTheme ? "text-white" : "")
              }
            >
              My Education
            </h2>
            {educationDetails.length > 0 &&
              educationDetails.map((value, index) => (
                <div
                  key={index}
                  className={
                    "bg-white  rounded p-4 mb-4 " +
                    (darkTheme ? "bg-dark" : "bg-white border")
                  }
                >
                  <p className="badge bg-primary text-2 fw-400">
                    {value.yearRange}
                  </p>
                  <h3 className={"text-5 " + (darkTheme ? "text-white" : "")}>
                    {value.title}
                  </h3>
                  <p className={darkTheme ? "text-primary" : "text-danger"}>
                    {value.place}
                  </p>
                  <p className={"mb-0 " + (darkTheme ? "text-white-50" : "")}>
                    {value.desc}
                  </p>
                </div>
              ))}
          </div>
          {/* My Experience */}
          <div className="col-md-6">
            <h2
              className={
                "text-6 fw-600 mb-4 " + (darkTheme ? "text-white" : "")
              }
            >
              My Experience
            </h2>
            {experienceDetails.length > 0 &&
              experienceDetails.map((value, index) => (
                <div
                  key={index}
                  className={
                    "bg-white  rounded p-4 mb-4 " +
                    (darkTheme ? "bg-dark" : "bg-white border")
                  }
                >
                  <p className="badge bg-primary text-2 fw-400">
                    {value.yearRange}
                  </p>
                  <h3 className={"text-5 " + (darkTheme ? "text-white" : "")}>
                    {value.title}
                  </h3>
                  <p className={darkTheme ? "text-primary" : "text-danger"}>
                    {value.place}
                  </p>
                  <p className={"mb-0 " + (darkTheme ? "text-white-50" : "")}>
                    {value.desc}
                  </p>
                </div>
              ))}
          </div>
        </div>
        {/* My Skills */}
        <h2
          className={
            "text-6 fw-600 mt-4 mb-4 " + (darkTheme ? "text-white" : "")
          }
        >
          My Skills
        </h2>
        <div className="row gx-5">
          {skills.length > 0 &&
            skills.map((skill, index) => (
              <div key={index} className="col-md-6">
                <p
                  className={
                    " fw-500 text-start mb-2 " +
                    (darkTheme ? "text-light" : "text-dark")
                  }
                >
                  {skill.name}{" "}
                  <span className="float-end">{skill.percent}%</span>
                </p>
                <div
                  className={
                    "progress progress-sm mb-4 " + (darkTheme ? "bg-dark" : "")
                  }
                >
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: skill.percent + "%" }}
                    aria-valuenow={skill.percent}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                </div>
              </div>
            ))}
        </div>
        <div className="text-center mt-5">
          {/* <a
            className="btn btn-outline-secondary rounded-pill shadow-none"
            href={resumeFile}
            download
          >
            Download CV
            <span className="ms-1">
              <i className="fas fa-download" />
            </span>
          </a> */}
        </div>
      </div>
    </section>
  );
};

export default Resume;
